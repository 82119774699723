$orange: #ff7e3d;
$blue: #0026ff;
$darkgray: #111111ee;

//actual CSS

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Franklin Gothic", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
body {
  margin: 0;
  font-family: "Gill Sans MT", "Haettenschweiler", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.topheading {
  animation: fadeIn 1s;
  font-size: calc(max(2vw, 20px));
}

.icon {
  height: 100px;
  width: 100px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 80px;
  padding: 10px;
}

.header {
  width: 100vw;
  height: 80px;
  margin-bottom: 10px;
  position: fixed;
  background-color: $darkgray;
  top: 0px;
  left: 0px;
  -ms-overflow-style: none;
  scrollbar-width: none; 

  &-mobile {
    @extend .header;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  
  &__tabs {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    box-shadow: 0 15px 10px $darkgray;
    overflow-x: scroll;
    overflow: hidden;

    &-mobile {
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }

    &-logoimg {
      height: 100px;
      z-index: 150;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  &__title {
    color: $blue;
    font-size: 30px;
  }

  &__tab {
    color: white;
    font-size: calc(max(24px, 1.4vw));
    padding: 10px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: darken($blue, 40);
      // background-image: url("./utils/linguafrancavector.png");
      color: $orange;
    }

    &-mobile {
      @extend .header__tab;
      width: 100px;
      height: 50px;
      background-color: black;
      padding: 5px;
      &-disabled {
        display: none;
      }
      border-radius: 0px;
    }

    &-active {
      @extend .header__tab;
      color: $orange;
      &-mobile {
        @extend .header__tab-mobile;
        color: $orange;
        &-disabled {
          @extend .header__tab-mobile-disabled;
        }
      }
    }
  }
}

#release {
  animation: fadeIn 1s;
}

#show {
  animation: fadeInWaitHalf 2s;
}

//home page
.home {
  &__container {
    display: flex;
    direction: row;
    width: 100vw;
    justify-content: space-around;
  }

  &-mobile {
    direction: column;
  }

  &__subcontainer {

    & p {
      font-size: 20px;
    }
    & a {
      color: white;
      &:hover {
        color: $orange;
      }
    }

    &-mobile {
      @extend .home__subcontainer;
      width: calc(90%);
    }

    &-photo {
      width: calc(min(90%, 400px));
    }

    width: calc(45%);
    border-radius: 12px;
    border: 5px solid $orange;
    background-color: darken($blue, 45);
    padding-bottom: 10px;
    margin: 10px;
    &-show {
      display: flex;
      direction: row;
      margin: 10px;
      margin-top: 25px;
      justify-content: space-around;
      align-items: center;
      &-text {
        font-size: calc(max(2vw, 18px));
      }
      &-ticket {
        font-size: calc(max(3vw, 30px));
        color: $orange;
        &:hover {
          color: $blue;
        }
      }
    }
  }
}

//about page
.about {
  &__beginning {
    font-size: 26px;
    margin: 0px;
  }
  &__container {
    width: calc(min(750px, 90%));
  }
  &__para {
    font-size: 20px;
  }
}

//music page
.music {
  &__spotifyembed {
    width: calc(max(400px, 45vw));
    height: 352px;
    border: 0;
    padding: 10px;
  }

  &__soundcloudembed {
    width: calc(max(400px, 45vw));
    height: 352px;
    border: 0;
    border-radius: 20px;
    padding: 10px;
  }

  &__bandcampembed {
    width: calc(min(400px, 90vw));
    height: 120px;
    border-radius: 12px;
    border-color: transparent;
  }
}

.contact {
  &__para {
    font-size: 20px;
    margin-bottom: 30px;
  }

  &__social {
    padding-left: calc(min(2vw, 50px));
    padding-right: calc(min(2vw, 50px));
    color: $orange;
    font-size: calc(max(5vw, 40px));
    &:hover {
      color: $blue;
    }
  }
}

.footer {
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100vw;
  box-shadow: 0px 0px 10px 10px $blue;
  background-color: $blue;
  padding-bottom: 15px;
  padding-top: 10px;
  & p {
    margin: 0px;
  }
}

//ANIMATIONS
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInWaitHalf {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
